import * as React from "react"
import qs from "query-string"
import { navigate } from "gatsby"

import SEO from "../../components/seo"
import Spinner from "../../components/ui/LoadingSpinner"
import { useStore } from "../../context/NewStoreContext"
import SwapCheckout from "../../components/v2/checkout/SwapCheckout"
import SwapCheckoutAdyen from "../../components/v2/checkout/SwapCheckoutAdyen"
import { hasAdyenEnabled } from "../../components/v2/utils/cart/hasAdyenEnabled"

const Swaps = () => {
  const [isLoading, setLoading] = React.useState(true)
  const store = useStore()

  React.useEffect(() => {
    if (!isLoading) {
      return
    }

    let { sct } = qs.parse(window.location.search)
    if (!sct) {
      navigate("/")
    }

    if (store.cart?.id) {
      setLoading(false)
    }
  }, [store.cart])

  return (
    <>
      <SEO title="Checkout" />
      {isLoading ? (
        <Spinner />
      ) : hasAdyenEnabled(store.cart) ? (
        <SwapCheckoutAdyen />
      ) : (
        <SwapCheckout />
      )}
    </>
  )
}

export default Swaps
